import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const myQuery = graphql`
  {
    strapiPlatform {
      helpText
      title
      platformCard {
        id
        icon
        title
      }
    }
  }
`

const PlatformArea = () => {
  const {
    strapiPlatform: { helpText, title, platformCard }
  } = useStaticQuery(myQuery)

  return (
    <section className='technology-area pt-100 pb-70'>
      <div className='container'>
        <div className='section-title text-center'>
          <span className='sp-color1'>{helpText}</span>
          <h2>{title}</h2>
        </div>

        <div className='row pt-45'>
          {platformCard.map(card => {
            return (
              <div className='col-lg-2 col-6' key={card.id}>
                <div className='technology-card'>
                  <i className={card.icon} />
                  <h3>{card.title}</h3>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default PlatformArea

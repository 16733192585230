import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

const myQuery = graphql`
  {
    strapiChoose {
      helpText
      title
      shortDesc
      chooseCard {
        id
        icon
        title
        shortDesc
      }
      Image {
        url
      }
    }
  }
`

const ChooseArea = () => {
  const {
    strapiChoose: { helpText, title, shortDesc, chooseCard, Image }
  } = useStaticQuery(myQuery)

  return (
    <div className='choose-area pt-100 pb-100'>
      <div className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-lg-6'>
            <div className='choose-img'>
              <img src={Image.url} alt='InnoAge Choose' />
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='choose-content mr-20'>
              <div className='section-title'>
                <span className='sp-color1'>{helpText}</span>
                <h2>{title}</h2>
                <p>{shortDesc}</p>
              </div>
              <div className='row'>
                {chooseCard.map(choose => {
                  return (
                    <div className='col-lg-6 col-6' key={choose.id}>
                      <div className='choose-content-card'>
                        <div className='content'>
                          <i className={choose.icon} />
                          <h3>{choose.title}</h3>
                        </div>
                        <p>{choose.shortDesc}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseArea

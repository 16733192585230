import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { getScaledImage } from '../../helper/utilities'

const myQuery = graphql`
  {
    strapiExperience {
      helpText
      title
      shortDesc
      experienceCard {
        id
        icon
        title
        shortDesc
      }
      images {
        url
      }
      subImg {
        url
      }
      imgTitle
      subText
    }
  }
`

const AboutArea = () => {
  const {
    strapiExperience: {
      helpText,
      title,
      shortDesc,
      experienceCard,
      images,
      subImg,
      imgTitle,
      subText
    }
  } = useStaticQuery(myQuery)

  return (
    <div className='about-area ptb-100'>
      <div className='container'>
        <div className='row align-items-center justify-content-center'>
          <div className='col-lg-6'>
            <div className='about-content mr-20'>
              <div className='section-title'>
                <span className='sp-color2'>{helpText}</span>
                <h2>{title}</h2>
                <p>{shortDesc}</p>
              </div>
              <div className='row'>
                {experienceCard.map(exCard => {
                  return (
                    <div className='col-lg-6 col-6' key={exCard.id}>
                      <div className='about-content-card'>
                        <i className={exCard.icon} />
                        <h3>{exCard.title}</h3>
                        <p>{exCard.shortDesc}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <div className='col-lg-6'>
            <div className='about-img'>
              <img src={images.url} alt='About' />
              <div className='sub-content'>
                <img src={getScaledImage(subImg.url, 160)} alt='About Sub' />
                <div className='content'>
                  <h3>{imgTitle}</h3>
                  <span>{subText}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutArea

import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const myQuery = graphql`
  {
    allStrapiBannerSliders(sort: { fields: id, order: ASC }) {
      nodes {
        id
        helpText
        title
        shortDce
        learnBtn
        learnBtnUrl
        contactBtn
        contactBtnUrl
        bannerImg {
          url
        }
      }
    }
  }
`

const Banner = () => {
  const {
    allStrapiBannerSliders: { nodes },
  } = useStaticQuery(myQuery)

  return (
    <div className="banner-slider-area">
      <OwlCarousel className="banner-slider owl-theme" loop margin={10} dots items={1} autoplay>
        {nodes.map(bannerlist => {
          return (
            <div
              className="banner-item"
              style={{
                backgroundImage: 'url(' + bannerlist.bannerImg.url + ')',
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
              }}
              key={bannerlist.id}
            >
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">
                    <div className="banner-item-content">
                      <span>{bannerlist.helpText}</span>
                      <h1>{bannerlist.title}</h1>
                      <p>{bannerlist.shortDce}</p>
                      <div className="banner-btn">
                        <Link
                          to={bannerlist.learnBtnUrl}
                          className="default-btn btn-bg-two border-radius-50"
                        >
                          {bannerlist.learnBtn}
                        </Link>
                        <Link
                          to={bannerlist.contactBtnUrl}
                          className="default-btn btn-bg-one border-radius-50 ml-20"
                        >
                          {bannerlist.contactBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </OwlCarousel>
    </div>
  )
}

export default Banner

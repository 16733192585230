import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

const myQuery = graphql`
  {
    strapiServicesHeader {
      helperText
      headerText
      shortDesc
    }
    allStrapiServices(limit: 4, sort: { fields: order }) {
      nodes {
        flatIcon
        title
        shortDesc
        learnBtn
        slug
        id
      }
    }
  }
`

const ServicesStyleArea = () => {
  const {
    strapiServicesHeader: { helperText, headerText, shortDesc },
    allStrapiServices: { nodes: services },
  } = useStaticQuery(myQuery)

  return (
    <section className="services-style-area pt-100 pb-70">
      <div className="container">
        <div className="section-title text-center">
          <span className="sp-color2">{helperText}</span>
          <h2>{headerText}</h2>
          <p className="margin-auto">{shortDesc}</p>
        </div>

        <div className="row pt-45">
          {services.map((servicescard, index) => {
            return (
              <div className="col-lg-3 col-sm-6" key={index}>
                <div className="services-card services-style-bg">
                  <i className={servicescard.flatIcon} />
                  <h3>
                    <Link to={`/services/${servicescard.slug}`}>{servicescard.title}</Link>
                  </h3>
                  <p>{servicescard.shortDesc}</p>
                  <Link to={`/services/${servicescard.slug}`} className="learn-btn">
                    {servicescard.learnBtn} <i className="bx bx-chevron-right" />
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default ServicesStyleArea

import * as React from 'react'

import Layout from '../components/_App/layout'
import Seo from '../components/_App/seo'
import TopHeader from '../components/Common/TopHeader'
import Navbar from '../components/_App/Navbar'
import PlatformArea from '../components/Index/PlatformArea'
import Footer from '../components/_App/Footer'
import Banner from '../components/Index/Banner'
import TestimonialsArea from '../components/Testimonials/TestimonialsArea'
import AboutArea from '../components/Index/AboutArea'
import ChooseArea from '../components/Common/ChooseArea'
import ServicesStyleArea from '../components/Services/ServicesStyleArea'
import BlogArea from '../components/Common/BlogArea'
import BrandArea from '../components/Common/BrandArea'

const IndexPage = () => {
  return (
    <Layout>
      <Seo maintitle='InnoAge-Technologies PVT. LTD - Bringing Innovation to Businesses' />

      <TopHeader />

      <Navbar />

      <Banner />

      <AboutArea />

      <ChooseArea />

      <ServicesStyleArea />

      <PlatformArea />

      <TestimonialsArea isLight />

      <BlogArea />

      <BrandArea />

      <Footer />
    </Layout>
  )
}

export default IndexPage
